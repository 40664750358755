.contact-form-bg {
  border-radius: 14px;
  border: 2px solid var(--Line);
  backdrop-filter: blur(26.5px);
  padding: 24px 8px;

  background-image: linear-gradient(#15151e, #15151e), var(--Line);
  background-origin: border-box, border-box;
  background-clip: padding-box, border-box;
  border: solid 2px transparent;
  border-radius: 14px;

  &.newsletter-box {
    background-image: unset;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent !important;
  }
}
.GetTouch {
  margin-top: 5rem;
  background-image: url(../assets/svg/get-touch-bg.svg);
}

.newsletter {
  background-color: var(--colors-main-colors-blue);
  padding: 3rem;
  border-radius: 18px;
}
.container-fluid .GetTouch {
  margin-top: 0;
  min-height: auto;
  margin-bottom: 5rem;
}
.GetTouch p {
  padding-right: 3rem;
}
.GetTouch p a {
  padding-right: 4rem;
  text-decoration: unset;
}
.GetTouch h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 120%;
  font-weight: 700;
}
.GetTouch h3 {
  font-size: 24px;
  /* font-style: normal; */
  font-weight: 700;
  line-height: 140%;
  /* margin-bottom: 1rem; */
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
}
.form-group {
  margin-bottom: 1.75rem;
}
/*  .form-control {
  background-color: #001770;
  border-color: #0b4a8f;
  color: #fff;
} */
.contact-form-bg .form-control.form-control-solid {
  border: 0 solid #969696 !important;
  color: var(--neutral-colors-100);
  -webkit-transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    -webkit-box-shadow 0.15s;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s,
    -webkit-box-shadow 0.15s;
  background: var(--neutral-colors-700);
  border-radius: 8px;
}

.form-control::-webkit-input-placeholder {
  color: var(--neutral-colors-500);
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: var(--neutral-colors-500);
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: var(--neutral-colors-500);
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: var(--neutral-colors-500);
  opacity: 1;
}
.form-control::placeholder {
  color: var(--neutral-colors-500);
  opacity: 1;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 9px);
  padding: 0.65rem 1rem;
  font-size: 14px;
}
textarea.form-control {
  height: auto;
}
.contact-form-bg .btn.btn-primary {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.contact-form-bg input:-webkit-autofill,
.contact-form-bg input:-webkit-autofill:focus,
.contact-form-bg input:-webkit-autofill:hover,
.contact-form-bg select:-webkit-autofill,
.contact-form-bg select:-webkit-autofill:focus,
.contact-form-bg select:-webkit-autofill:hover,
.contact-form-bg textarea:-webkit-autofill,
.contact-form-bg textarea:-webkit-autofill:focus,
.contact-form-bg textarea:-webkit-autofill:hover {
  border: 1px solid #969696;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px var(--neutral-colors-600) inset !important;
  transition: background-color 5000s ease-in-out;
}

.form-control:focus {
  box-shadow: 0 0 0 1px var(--neutral-colors-500) inset !important;
}
.invalid-feedback {
  text-transform: capitalize;
}
.mt-n-5 {
  margin-top: -5rem;
}
@media (max-width: 991.98px) {
  .mt-n-5 {
    margin-top: 3rem;
  }
}
@media (max-width: 767.98px) {
  .mt-n-5 {
    margin-top: 2rem;
  }
  .custom-margining {
    margin-top: -8rem;
  }
  .rights p {
    text-align: center;
    color: #a3a3a3;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 20px;
  }
  .contact-form-bg .btn.btn-primary {
    border-radius: 30px;
    width: 100%;
    margin-top: 20px;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  .GetTouch h1 {
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 2rem;
  }
}

/* for newsletter */

.newsletter-box {
  &.contact-form-bg {
    input,
    select,
    textarea {
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:hover {
        border: 1px solid white;
        -webkit-text-fill-color: #fff;
        -webkit-box-shadow: 0 0 0 1000px #0b2666 inset !important;
        transition: background-color 5000s ease-in-out;
      }
    }

    .custom-primary {
      border-color: var(--neutral-colors-100) !important;
    }
  }

  .form-control {
    color: var(--neutral-colors-100);
    -webkit-transition: color 0.15s, background-color 0.15s, border-color 0.15s,
      -webkit-box-shadow 0.15s;
    transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s,
      -webkit-box-shadow 0.15s;
    background: rgba(255, 255, 255, 0.2) !important;
    border-radius: 8px;
    &:focus {
      box-shadow: 0 0 0 1px var(--colors-main-colors-blue) inset !important;
    }
  }
}
