.footer .rights p,
p.text-footer {
  font-style: normal;
  font-weight: 400;
}
.footer {
  color: var(--neutral-colors-400);
}
.footer hr {
  color: var(--neutral-colors-600);
  opacity: 0.5;
  width: 150%;
  margin-inline: -25%;
}
.footer .rights p {
  font-size: 14px;
  line-height: 24px;
  color: #b3bbd3;
}
.footer .title-div img {
  margin-bottom: 1.7rem;
  width: 110px;
}
p.text-footer {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-transform: capitalize;
}
ul.social-ul-footer {
  display: flex;
  padding-left: 0;
  text-align: center;
  margin: 0 auto 1rem;
  display: flex;
  font-size: 20px;
}
a.linkedin-team {
  width: 40px;
  height: 40px;
  display: block;
  line-height: 37px;
  border-radius: 50%;
  background: var(--neutral-colors-700);
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
a.linkedin-team:hover {
  background: var(--bs-blue);
}
ul.social-ul-footer li {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.content-footer {
  padding-bottom: 1.5rem;
  /* margin-bottom: 1.5rem; */
}
.footer {
  padding-top: 6rem;
  margin-bottom: 2rem;
}

@media (max-width: 1200px) {
  .footer hr {
    color: var(--neutral-colors-600);
    opacity: 0.5;
    width: 100%;
    margin-inline: unset;
  }
}

@media (max-width: 991.98px) {
  .footer {
    padding-top: 3rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
  }
  .content-footer {
    padding-bottom: 0;
  }
}

.big-puzzle {
  top: -17vh;
  left: 12vw;
}

.sm-puccle {
  top: 3rem;
  right: 15vw;
}

@media (max-width: 1200px) {
  .big-puzzle {
    width: 300px;
    left: 10vw;
    top: -20vh;

  }
  .sm-puccle {
    width: 180px;
    right: 10vw;
  }
}
