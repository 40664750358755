@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-Thin.ttf");
    font-weight: 100;
    font-display: swap;
}

@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-ExtraLight.ttf");
    font-weight: 200;
    font-display: swap;
}

@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-Light.ttf");
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-Regular.ttf");
    font-weight: 400;
    font-display: swap;
}

@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-Medium.ttf");
    font-weight: 500;
    font-display: swap;
}

@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-SemiBold.ttf");
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-Bold.ttf");
    font-weight: 700;
    font-display: swap;
}

@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-ExtraBold.ttf");
    font-weight: 800;
    font-display: swap;
}

@font-face{
    font-family: "Inter";
    src: url("../assets/fonts/inter/Inter-Black.ttf");
    font-weight: 900;
    font-display: swap;
}
