.nav-link {
  color: #fff !important;
  font-size: 1rem;
}
.navbar-nav a {
  padding: 0.85rem 1.5rem !important;
}
.h-70px,
nav.navbar {
  height: 70px;
}
.menu-item-default a:hover,
button.custom-primary.btn.btn-secondary:hover {
  color: #fff;
}

.dropdown-item:active,
.dropdown-item:focus {
  background: rgba(156, 68, 245, 0.4);
  border-radius: 7.09371px;
}
a.dropdown-item img {
  margin-right: 7px;
  height: 22px;
  margin-left: -4px;
}
.dropdown-toggle::after {
  vertical-align: 0.1em;
  margin-top: 18px;
}
.header .header-top {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 0 solid;
}
.btn-side-menu {
  height: calc(1.2em + 1.65rem + 5px);
  padding: 0 0.5rem !important;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}
.navbar-expand-lg .navbar-nav:last-child {
  margin-right: 0;
  margin-left: auto;
}
.menuscroll nav.navbar {
  height: 70px;
}
.menuscroll,
.menuscroll.header-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 97;
  height: 70px;
  -webkit-animation: 0.5s header-scroll-animation;
  animation: 0.5s header-scroll-animation;
  background-color: #1d1d1dc4;
  -webkit-box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
}
.top-menue-title.dropdown-toggle.btn.btn-outline-secondary {
  padding: 0.85rem 1rem !important;
  border: 0;
  background: #eee0 !important;
  color: #fff;
  text-transform: capitalize;
}
body .DropdownMenu {
  background: #3c7cbf52;
  box-shadow: 3px 5px 35px rgb(0 0 0 / 21%);
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  border-radius: 22px;
  padding: 20px;
  min-width: max-content;
  margin-top: -1px !important;
}
body .DropdownMenu .navbar-nav {
  display: block;
  margin-bottom: 1px;
}
body .DropdownMenu .nav-link:hover,
body .DropdownMenu .navbar-nav .nav-link.active {
  background: #0000001c;
  border-radius: 10px;
}
.top-menue-title.dropdown-toggle.btn.btn-outline-secondary::after {
  margin-top: 0 !important;
  margin-left: 5px !important;
}
#walletconnect-qrcode-modal a {
  color: #333;
}
.navbar-nav button.custom-secondary.btn.btn-outline-secondary.px-5 {
  padding: 0.1rem 1.6rem 0.15rem !important;
}

@-webkit-keyframes header-scroll-animation {
  from {
    top: -70px;
  }
  to {
    top: 0;
  }
}
@keyframes header-scroll-animation {
  from {
    top: -70px;
  }
  to {
    top: 0;
  }
}
@media (max-width: 767.98px) {
  .header-top {
    zoom: 0.9;
  }
  .container-fluid {
    padding: 0 30px;
  }
  .navbar-collapse {
    padding: 28.3932px 21.6329px;
    background: rgb(10 26 45);
    box-shadow: 0 21.6329px 86.5317px -64.8987px rgb(31 47 70 / 74%);
    border-radius: 32.4494px 0 32.4494px 32.4494px;
    min-width: 270px;
    margin-right: 0;
    margin-left: auto;
    margin-top: 0;
    flex-basis: unset;
    flex-grow: unset;
    align-items: center;
    position: absolute;
    right: 4px;
    top: 76px;
  }
  .navbar-collapse .navbar-nav a {
    padding: 15px !important;
    color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    color: #fff !important;
  }
  .nav-SideMenu.navbar-nav {
    padding: 0.85rem 1.5rem !important;
  }
  body .navbar-nav .btn-side-menu {
    width: fit-content;
  }
  button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-margining {
    margin-top: -24rem;
  }
  .itemslide img {
    margin-bottom: -40px;
    margin-top: -110px;
    width: 80%;
  }
  .itemslide {
    width: 98%;
  }
  .container-fluid {
    padding: 0 30px;
  }
  button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px !important;
  }
}
@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav a {
    padding: 15px !important;
    color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    color: #fff !important;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link i {
    color: #fff;
    font-size: 10px;
    float: right;
    margin-top: 7px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link.active i {
    display: block !important;
  }
  .nav-SideMenu.navbar-nav {
    padding: 0.85rem 1.5rem !important;
  }
  body .navbar-nav .btn-side-menu {
    width: fit-content;
  }

  button.custom-secondary.btn.btn-outline-secondary {
    border: 0 solid #969696;
    padding: 2.5px 4px 5px !important;
    margin-right: 0;
    margin-left: auto;
  }
  button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px !important;
  }
}
@media (min-width: 992px) and (max-width: 1070px) {
  .header-menu .menu-nav > .menu-item > .menu-link {
    padding: 0.85rem 0.5rem;
    font-size: 1rem;
  }
  img.logo {
    height: 30px !important;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .top-menue-title.dropdown-toggle.btn.btn-outline-secondary,
  body .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.5rem 0.5rem !important;
    font-size: 1rem;
    white-space: nowrap;
  }
  .navbar-expand-lg .navbar-nav {
    margin-right: 0.3rem;
  }
}
.navbar-brand {
  width: auto;
  text-align: left;
}
.special-link {
  font-size: 14px;
  font-style: italic;
}
.navbar-nav button {
  padding: 0.55rem 1rem !important;
}
button.navbar-toggler i {
  color: #fff !important;
  font-size: 35px !important;
}
.navbar-toggler:focus {
  box-shadow: unset !important;
}
img.logo-mobile {
  width: 50px;
}
.navbar-toggler {
  padding: 0 !important;
  font-size: 40px !important;
  line-height: 1 !important;
  color: rgb(255 255 255) !important;
  background-color: transparent !important;
  border: 0 !important;
}
@media (max-width: 991.5px) {
  .mobile-logo,
  div#Contact {
    display: block;
  }
  .hero-section-box {
    padding: 2.5rem 0 0;
  }
  .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .menuscroll .navbar {
    padding: 0 30px;
  }

  .header-top .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff !important;
  }
}
.mobile-navbar {
  width: 100%;
  height: 120vh;
  background: #1c1d22;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
  transform: translateX(-100%);
  z-index: 999;
}
.mobile-navbar-in {
  transform: translateX(0);
}

.mobile-navbar-child {
  background: #1c1d22;
  cursor: pointer;
}
.mobile-navbar-child .title {
  padding: 0.85rem 1.5rem !important;
  border: 0;
  background: #eee0 !important;
  color: #fff;
  text-transform: capitalize;
}
.menu-dots {
  margin-right: 40px;
  font-size: 22px;
}
.menu-item-default {
  background-color: #1c1d22;
  width: 100%;
  height: 120vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
  transform: translateX(200%);
}
.menu-item-default a:hover {
  color: #fff;
}
.menu-item-open {
  transform: translateX(0);
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    height: auto !important;
  }
  .header-top {
    height: 70px;
    background: transparent;
  }
  .mobile-navbar {
    display: none;
  }
  .navbar-collapse {
    padding: 28.3932px 21.6329px;
    box-shadow: 0 21.6329px 86.5317px -64.8987px rgb(31 47 70 / 74%);
    border-radius: 32.4494px 0 32.4494px 32.4494px;
  }
}
@media (max-width: 992px) {
  #basic-navbar-nav {
    display: none !important;
  }
}
