@import "_variables.scss";
@import "fonts.css";
@import "bootstrap/scss/bootstrap";
@import "react-toastify/dist/ReactToastify.css";
@import "header.css";
@import "footer.css";
@import "GetTouch.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

:root {
  --font-family: "Inter", sans-serif;

  --border-radius-sm: 8px;
  --border-radius: 12px;
  --border-radius-lg: 16px;
  --border-radius-xl: 24px;

  --colors-main-colors-blue: #144ee3;
  --colors-main-colors-color-4: #ffcb46;
  --colors-main-colors-orange: #ff7c33;
  --colors-main-colors-pink: #f64abb;
  --colors-main-colors-gradient-1: linear-gradient(
    90deg,
    #27a4ff 0%,
    #3354ff 28%,
    #7433ff 50%,
    #f64abb 79%,
    #ff7c33 100%
  );
  --radial: radial-gradient(94.84% 95.93% at 47.31% 52.21%, #2b2b3b 0%, #15151e 100%);
  --Line: linear-gradient(
    130deg,
    rgba(255, 255, 255, 0.03) -0.85%,
    rgba(255, 255, 255, 0.2) 52.12%,
    rgba(255, 255, 255, 0.03) 100.85%
  );
  --neutral-colors-100: #fff;
  --neutral-colors-200: #f6f6fa;
  --neutral-colors-300: #e4e4ed;
  --neutral-colors-400: #c3c3d0;
  --neutral-colors-500: #8f8fa5;
  --neutral-colors-600: #4d4d63;
  --neutral-colors-700: #2b2b3b;
  --neutral-colors-800: #15151e;

  --bs-blue: #144ee3;
  --bs-orange: #ff7c33;
  --bs-yellow: #ffcb46;
  --bs-primary: #144ee3;

  --bs-body-font-family: var(--font-family);
  --bs-heading-color: var(--neutral-colors-100);
  --bs-body-color: var(--neutral-colors-400);
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  /* color: rgb(var(--foreground-rgb)); */
  background: #15151e;
  overflow-y: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--neutral-colors-400);
}

span {
  color: var(--neutral-colors-400);
}

.cursor-pointer {
  cursor: pointer !important;
}

.color-primary {
  color: var(--neutral-colors-400);
}

.text-gradient {
  background: var(--colors-main-colors-gradient-1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq-grad {
  background-size: 68% 100%;
}

.half-gradient {
  background-size: 60% 100%;
}

.bg-gradient {
  background: var(--colors-main-colors-gradient-1) !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.pl-10,
.px-10 {
  padding-left: 2.5rem !important;
}
.pr-10,
.px-10 {
  padding-right: 2.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 2.5rem !important;
}
.mb-10,
.my-10 {
  margin-bottom: 2.5rem !important;
}

.mt-20,
.my-20 {
  margin-top: 5rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 5rem !important;
}

.mt-50,
.my-50 {
  margin-top: 12.5rem !important;
}

.mb-50,
.my-50 {
  margin-bottom: 12.5rem !important;
}

.pt-20,
.py-20 {
  padding-top: 5rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 5rem !important;
}

.sec-py {
  @media (max-width: 992px) {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}
.mt-50px {
  margin-top: 42px !important;
}
.scale-up {
  transition: all 0.3s ease-in-out;
}

.scale-up:hover {
  transform: scale(1.05);
}

.extra-scale:hover {
  transform: scale(1.15) !important;
}

.category-item {
  padding: 3px 8px;
  border-radius: 6px;
  background: var(--neutral-colors-600);
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

@for $i from 1 through 5 {
  .lines-#{$i} {
    -webkit-line-clamp: $i;
    line-clamp: $i;
  }
}

.card-img-top {
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
}

.card.blog-card {
  --bs-card-border-width: 0 !important;
  --bs-card-border-radius: var(--border-radius) !important;
  --bs-card-bg: var(--neutral-colors-700) !important;
}

.card.team-item {
  --bs-card-border-width: 0 !important;
  --bs-card-border-radius: 0 !important;
  --bs-card-bg: transparent !important;
}

.blog-img-aspect {
  aspect-ratio: 320 / 230 !important;
}

.team-item .card-body {
  padding: var(--bs-card-spacer-y) 0;
}

.bg-team {
  transition: background-position 0.3s ease-in-out, background-size 0.3s ease-in-out;
}
.team-item:hover .bg-team {
  background-position: center center !important ;
  background-size: 120% !important;
}

.py-special {
  padding-top: 3rem !important;
  padding-bottom: 4rem !important;
}

.ballon-div {
  margin-bottom: -3.25rem;
  z-index: 999;
}

.pin {
  width: calc(100% + 0.66vw);
  /* padding-bottom: 3rem; */
}

.pin.left {
  margin-left: -0.6vw;
}

.pin.right {
  margin-right: -0.66rem;
  transform: rotate(180deg);
}

.mt--1 {
  margin-top: -0.66rem !important;
}

.homeFlex {
  display: flex;
  flex-direction: column;
  display: -ms-flexbox;
}

@media (max-width: 769px) {
  .homeFlex .header-top:not(.menuscroll) {
    margin-top: 4rem;
    margin-bottom: -4rem;
  }
}

@media (max-width: 991px) {
  .overflow-hidden-mob {
    overflow: hidden;
  }
}

@media (max-width: 520px) {
  .ballon-width {
    width: 350px;
  }
  .ballon-div {
    margin-bottom: -2.9rem;
  }
}

.underHero {
  margin-top: -20rem;
  transform: translateY(300px);
}

.btn {
  border-radius: 48px;
}

.btn.home-btn {
  border: 2px solid var(--neutral-colors-600);
  color: var(--neutral-colors-100, #fff);
  text-align: center;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  padding: 1rem 1.5rem;
  transition: all 0.3s ease-in-out;
}

.arr-right-btn {
  border: 2px solid var(--neutral-colors-600);
  border-radius: 48px;
  color: var(--neutral-colors-100, #fff);
  text-align: center;
  vertical-align: middle;
  background: transparent;
  font-size: 32px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.arr-right-btn .position-absolute {
  transition: all 0.3s ease-in-out;
  transform: translateX(-40px);
}

.hero-action:hover .arr-right-btn,
.hero-action:hover .btn.home-btn {
  background: var(--colors-main-colors-blue);
  border-color: var(--colors-main-colors-blue);
}

.hero-action .arr-right-btn svg {
  transition: all 0.3s ease-in-out;
}

.hero-action:hover .arr-right-btn svg {
  transform: translateX(40px);
}

.hero-action:hover .arr-right-btn .position-absolute {
  transform: translateX(0px);
}

.swiper-slide {
  display: block;
  width: auto;
  padding: 8px;
  margin-right: 8px;
}

.slick-dots {
  bottom: -50px !important;
  li {
    button:before {
      color: var(--neutral-colors-100) !important;
      font-size: 8px;
    }
    &.slick-active {
      button:before {
        border: 2px solid var(--colors-main-colors-pink);
        padding: 8px 4px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.star {
  position: absolute;
  width: 1px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  opacity: 1;
  -webkit-animation: twinkle infinite alternate;
  animation: twinkle infinite alternate;
  transform: scale(0.05);
  z-index: 999999;

  &.animateOnAppear {
    transform: scale(0.1);
    -webkit-animation: twinkle infinite alternate, minimize 2s ease-in-out forwards;
    animation: twinkle infinite alternate, minimize 2s ease-in-out forwards;
  }
}

@-webkit-keyframes minimize {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0.05);
  }
}

@keyframes minimize {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0.05);
  }
}

@-webkit-keyframes twinkle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes twinkle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

button.custom-primary.btn.btn-primary,
button.custom-primary.btn.btn-secondary {
  padding: 15px 32px !important;
  font-weight: 500;
  line-height: 0px;
  font-size: 16px;
  max-height: 54px;
}

.navbar-nav .custom-primary.btn.btn-primary {
  margin-left: 1.25rem;
  border-radius: 12px;
  padding: 8px 20px !important;
  height: 40px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;

  &:hover {
    background: var(--colors-main-colors-blue);
    border-color: var(--colors-main-colors-blue);

    &::before {
      opacity: 0;
    }
  }
}

.custom-primary.btn.btn-primary {
  position: relative;
  background: #fff0;
  border-radius: 8px;
  border: none;
  box-shadow: 0 0 0 2px var(--neutral-colors-600, #4d4d63);
  max-height: 48px;
  padding: 15px 32px !important;
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--colors-main-colors-gradient-1);
    opacity: 0;
    transition: opacity 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    z-index: -1;
    border-radius: inherit;
  }

  &:hover {
    box-shadow: 0 0 0 2px transparent;

    &::before {
      opacity: 1;
    }
  }
}

.custom-primary.btn.btn-secondary {
  background: #fff;
  border-radius: 8px;
  border-color: #fff;
  color: #1b212e;

  &:hover {
    background: #fff;
    color: #1b212e;
  }
}

.calendly-inline-widget iframe,
.calendly-badge-widget iframe,
.calendly-overlay iframe {
  /*
    Values other than `display: inline` cause a scrolling bug on iOS where the underlying page
    scrolls instead of the iframe
    */
  display: inline;
  width: 100%;
  height: 100%;

  body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.bg-700 {
  background: var(--neutral-colors-700);
}

.bg-600 {
  background: var(--neutral-colors-600) !important;
}

.bg-transparent {
  background: transparent !important;
}

.bg-dark {
  background: #1c1c26 !important;
}

.bold {
  font-weight: 700 !important;
}

.semibold {
  font-weight: 600 !important;
}

.medium {
  font-weight: 500 !important;
}

.normal {
  font-weight: 400 !important;
}

.light {
  font-weight: 300 !important;
}
$radius: 14px;

@mixin rounded-lg-top {
  border-top-left-radius: $radius !important;
  border-top-right-radius: $radius !important;
}

.rounded-lg {
  border-radius: $radius !important;

  &-start {
    border-top-left-radius: $radius !important;
    border-bottom-left-radius: $radius !important;
  }

  &-end {
    border-top-right-radius: $radius !important;
    border-bottom-right-radius: $radius !important;
  }

  &-top {
    @include rounded-lg-top;
  }

  &-bottom {
    border-bottom-left-radius: $radius !important;
    border-bottom-right-radius: $radius !important;
  }
}

.lg-rounded-lg-top {
  @media (max-width: 992px) {
    @include rounded-lg-top;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.text-xs {
  font-size: 0.75rem !important;
}
.text-sm {
  font-size: 0.875rem !important;
}

.text-md {
  font-size: 1rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}

.text-muted {
  color: var(--neutral-colors-500) !important;
}

.blog-tap {
  transition: all 0.1s ease-in-out;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 2px solid var(--neutral-colors-600);
  color: var(--neutral-colors-100);
  &.active {
    background-color: var(--colors-main-colors-blue);
    border-color: var(--colors-main-colors-blue);
  }

  &:hover {
    border-color: var(--colors-main-colors-blue);
  }
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-right: auto;
  margin-left: 5px;
  margin-top: 8px;

  &::after {
    content: " *";
    color: red;
  }
}

.hover-breadcrumb {
  transition: all 0.3s ease-in-out;
  &:hover {
    color: var(--colors-main-colors-orange) !important;
  }
}

.container-like {
  padding: 0 30px !important;
}

.grad-glow {
  left: -28vw;
  width: 50vw;
  margin-top: -22%;
  z-index: -1;
}

.blue-glow {
  right: -25vw;
  width: 50vw;
  margin-top: -40%;
  z-index: -1;
}

.grad-glow.sec {
  margin-top: -10% !important;
}

.faq-acc button,
.faq-acc.accordion {
  background-color: transparent !important;
}

@media (max-width: 500px) {
  .sm-30px {
    font-size: 30px !important;
  }
  .faq-head {
    gap: 4px;
    line-height: 32px !important;
  }
}

.accordion-button,
.faq-acc .accordion-item,
.faq-acc.accordion {
  border: none !important;
  box-shadow: none !important;
}

.faq-acc.accordion {
  --bs-accordion-btn-active-icon: url(../assets/svg/arr-down.svg) !important;
  --bs-accordion-btn-icon: url(../assets/svg/arr-up.svg) !important;
  --bs-accordion-bg: transparent !important;
  --bs-accordion-btn-color: #fff !important;
  --bs-accordion-color: #fff !important;
  --bs-accordion-btn-icon-width: 12px;
}
.accordion-button {
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #fff;
  border-bottom: 1px solid #282f3d !important;
  &:not(.collapsed) {
    color: #fff !important;
  }
}

.faq-acc .index {
  svg {
    transition: all 0.3s ease-in-out;
    circle {
      transition: all 0.3s ease-in-out;
    }
  }

  &.active {
    svg {
      circle {
        stroke: var(--colors-main-colors-blue);
      }
      fill: var(--neutral-colors-100);
    }
  }
}

.accordion-body {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #b3bbd3 !important;
}

.mr-8 {
  margin-right: 2rem;
}

.read-more {
  transition: all 0.3s ease-in-out;
  svg {
    transition: transform 0.3s ease-in-out;
  }
  span {
    width: 18px;
    height: 18px;
    padding-top: 2px;
    overflow: hidden;
    svg:not(:first-child) {
      transform: translateX(-18px);
    }
  }
  &:hover {
    color: var(--colors-main-colors-blue) !important;
    span {
      svg:not(:first-child) {
        transform: translateX(0px);
        color: #144ee3;
      }
      svg:not(:last-child) {
        transform: translateX(18px);
      }
    }
  }
}

.lottiePlayer {
  margin-top: -3rem;
}

.under-hero {
  margin-top: -28%;
  margin-bottom: 15rem;
}

@media (max-width: 500px) {
  .under-hero {
    margin-top: 0%;
  }
  .lottiePlayer {
    margin-top: 2rem !important;
  }
}

@media (max-width: 722px) {
  .lottiePlayer {
    margin-top: 0;
  }
}

/* For Lottie File Animation */
.lottiePlayer {
  -webkit-animation: lottieSlideDown 2s ease-in-out forwards;
  animation: lottieSlideDown 2s ease-in-out forwards;
  transform: translateY(-100%);
}

@-webkit-keyframes lottieSlideDown {
  to {
    transform: translateY(0);
  }
}

@keyframes lottieSlideDown {
  to {
    transform: translateY(0);
  }
}

/* For Text Animation */
.under-hero {
  -webkit-animation: textSlideUp 2s ease-in-out forwards;
  animation: textSlideUp 2s ease-in-out forwards;
  transform: translateY(400%);
  opacity: 0;
}

@-webkit-keyframes textSlideUp {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes textSlideUp {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.w-140 {
  width: 140% !important;
  margin-left: -20%;
}

@media (max-width: 992px) {
  .mob-w-75 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.text-lg {
  font-size: 1.125rem !important;
}

.mb--3 {
  margin-bottom: -1rem !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.calendly-popup-content iframe {
  margin-top: 0;
  height: 702px !important;
}

.rotate {
  -webkit-animation: rotate 5s linear infinite;
  animation: rotate 5s linear infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.homeTeam {
  object-fit: cover !important;
  aspect-ratio: 1 / 1 !important;
  -o-object-position: center top;
  object-position: center top;
}

@media (max-width: 469px) {
  .d-after-custom {
    display: block !important;
  }
  .d-custom {
    display: none !important;
  }
}

.lg-px-30 {
  @media (max-width: 992px) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@for $i from 1 through 10 {
  .z-#{$i} {
    z-index: $i;
  }
}

.tooltip-father {
  .toooltip {
    opacity: 0;
    transition: opacity 0.5s ease-in;
    // z-index: -1;
  }

  &:hover {
    .toooltip {
      opacity: 1;
      transition-delay: 1s;
    }
  }
}

@media (min-width: 768px) {
  .mb-md-50 {
    margin-bottom: 12.5rem !important;
  }
}
